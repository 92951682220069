import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./index.scss";

const FaqPage = () => {
  return (
    <>
      {/* <h1 className="faq-page-header">FAQ</h1> */}
      <Box
        style={{
          marginTop: window.location.href.includes("faq") ? "3rem" : "0",
        }}
        className="faq-page-container"
      >
        <Box className="faq-page-textBox">
          <h3 className="faq-page-h3">Frequently asked questions</h3>
          <Box className="faq-page-span">
            <span>
              Onward and upward, productize the deliverables and focus on the
              bottom line drop-dead date translating our vision of having a
              market leading platfrom drop-dead date.
            </span>
          </Box>
        </Box>
        <Box className="faq-types-container-main">
          <Box className="faq-types-container">
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={
                    <ExpandMore style={{ color: "black !important" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    Are my funds ensured and protected?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Customer funds with a Futures Clearing Merchant are held in
                    segregation under Commodity Futures Trading Commission rule
                    1.25 (“CFTC 1.25”). All our clearing firms fully comply with
                    this key customer protection rule and furthermore exercise
                    prudence when investing customer balances. This segregation
                    provides sufficient safety to an investor, but there is no
                    level of regulation or other safeguards that can 100%
                    protect an investor in the case of fraud or other
                    inappropriate activity. However, please feel free to check
                    out the government filed FOCUS reports on our FCM’s.
                    <span
                      style={{
                        cursor: "pointer",
                        fontVariant: "petite-caps",
                        color: "white",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                      onClick={() =>
                        window.open(
                          "https://www.cftc.gov/sites/default/files/sites/default/files/idc/groups/public/%40requestsandactions/documents/ifdocs/08%20-%20FCM%20Webpage%20Update%20-%20August%202019.pdf"
                        )
                      }
                    >
                      CLICK HERE
                    </span>
                    to go to check the Financial Data for FCMs.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What financial products can I trade with you?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets is dedicated to providing a variety of
                    instruments across all major markets. You can trade with 60+
                    instruments including currency pairs, commodities, equity
                    indices and cryptocurrencies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I withdraw funds?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Please follow these simple steps:<br></br> 1.Log into your
                    account on aquila-markets.com. <br></br>2.Go to Wallet -
                    Withdraw <br></br> 3.Fill out an online withdrawal form
                    through your account and click Send.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I make a deposit?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Please follow these simple steps:<br></br> 1. Log into your
                    account on aquila-markets.com <br></br>2. Go to Wallet
                    Deposit <br></br>3. Select a Deposit - method <br></br>4.
                    Enter the amount you are depositing and follow the steps
                    which vary depending on a deposit method.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
          </Box>
          <Box className="faq-types-container">
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What withdrawal methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets offers several methods for withdrawing your
                    funds:
                    <br></br>○ Bank Transfer <br></br>○ Cryptocurrencies
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What deposit methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets offers several methods for depositing your
                    funds:
                    <br></br> ○ Bank Transfer<br></br> ○ Debit/Credit Card (Visa
                    or MasterCard only) <br></br>○ Bitcoin
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How can I contact Aquila Markets?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Aquila Markets provides dedicated Customer support in
                    several languages. Please feel free to email, call or
                    contact us by Live Chat on our website. You can find phone
                    and email details if you
                    <></>
                    <span
                      style={{
                        cursor: "pointer",
                        fontVariant: "petite-caps",
                        color: "red",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                      onClick={() =>
                        window.open("https://aquila-markets.com/contact-us")
                      }
                    >
                      CLICK HERE
                    </span>
                    .
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I receive account updates?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Daily and monthly statements are available in your online
                    savings account on the portal provided by us where your
                    accout is held. You will receive login information for the
                    online portal at your registration process after you open
                    and fund the account. You will receive updates via phone or
                    email as well on daily basis.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FaqPage;
