import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";

function KycPolicyPage() {
  return (
    <Box className="kyc-policy-page-container">
      <Box>
        <h1 className="kyc-policy-page-header">KYC Policy</h1>
      </Box>
      <Box className="kyc-policy-page-textBox">
        <span className="kyc-policy-page-span">
          Know Your Client Policy Aquila Markets (the “Company”) Because of the
          company's commitment to the AML and KYC policies, each client of the
          company must finish a verification procedure. A client will not be
          able to engage in active trading before all required documents are
          delivered and the identity authentication process is complete. Client
          acknowledges that documents which are provided by you in a foreign
          language must be sent with a dated translation, signed and certified
          by an independent person competent and authorised to approve such
          translation being the accurate, truthful translation of the original.
          In order to complete the compliance procedure, the following documents
          are required: PROOF OF IDENTITY In the form of a color copy of User’s:
          1. Valid Passport or 2. Driver License or 3. ID Both front and back,
          indicating: 1. User’s Legal Name; 2. User’s Date of birth; 3.
          Document’s Expiry Date; 4. User’s Signature; 5. User’s Picture. PROOF
          OF CARD OWNERSHIP The User will provide a color copy of User’s credit
          card, front and back. The provided copy must be with the following
          elements visible: 1. Cardholder Name; 2. Expiration date; 3. Front
          side - last 4 digits; 4. Back Side – Signature; For User’s security,
          other identifying details should be kept concealed. PROOF OF ADDRESS
          In the form of: 1. A copy of an original current utility bill dating
          not longer than three (3) months back; or 2. A copy of an original
          Bank or Credit card statement, tax statements or local authority tax
          bill or government-issued proof of address; all must be dating not
          longer than three (3) months back. The provided copy must be with the
          following elements visible: 1. Issuer; 2. Name and address; 3. Date of
          issue; DECLARATION OF DEPOSIT FORM Please print, fill-out and hand
          sign the following form. The document must be scanned with the
          following elements visible: 1. Date of deposit; 2. Exact hour (with
          timezone); 3. Amount and currency; 4. Last 4 digits; 5. Signature; A
          declaration of deposit form must be signed by the User.
        </span>
      </Box>
    </Box>
  );
}

export default KycPolicyPage;
